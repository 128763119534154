import { Injectable } from '@angular/core';
import { BaseKundenDataService } from './base-kunden-data.service';
import { CheckboxFilter } from '../../interfaces/base-entity-filter.interface';

@Injectable({
    providedIn: 'root'
})
export class KontakteDataService extends BaseKundenDataService {
    public loadEntities(): void {
        this.replaceFilterByName(new CheckboxFilter({ field: 'isFirma', operator: 'eq', value: false }));
        super.loadEntities();
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KundeVersion } from '../../../../data/interfaces/kunde-version.interface';
import { KontakteDataService } from '../../../../data/services/kunden/kontakte-data.service';
import { KundeFormService } from '../../../services/forms/kunde-form.service';
import { Router } from '@angular/router';

@Component({
    selector: 'effimod-kunde-details-form',
    templateUrl: './kunde-details-form.component.html',
    styleUrls: ['./kunde-details-form.component.scss']
})
export class KundeDetailsFormComponent implements OnInit {
    @Input() veranstaltungBezeichnung: string;
    @Input() kundeForm: FormGroup;
    @Input() kundeVersion: KundeVersion;
    @Input() isWriteMode: boolean = false;
    @Input() showAdresse: boolean = true;
    @Input() showAdditionalInformation: boolean = false;

    isEditing: boolean = false;
    public firmaEmpty = true;
    private originalKundeVersion: KundeVersion | null = null;

    constructor(private readonly kontakteDataService: KontakteDataService,
                private readonly kundeFormService: KundeFormService,
                private readonly router: Router) { }

    ngOnInit() {
        if (!this.isWriteMode) {
            this.ensureFormIsNotEditable();
        }

        this.populateForm(this.kundeVersion);
    }

    private populateForm(kunde: KundeVersion) {
        if (kunde) {
            this.kundeFormService.patchForm(this.kundeForm, kunde, true);
            this.firmaEmpty = !kunde.firma;
            this.originalKundeVersion = JSON.parse(JSON.stringify(kunde)); // Deep copy to preserve original data
        } else {
            console.error('Failed to populate form: Kunde is undefined or null');
        }
    }

    ensureFormIsNotEditable() {
        this.kundeForm.disable({emitEvent: false});
    }

    toggleEditMode() {
        this.isEditing = !this.isEditing;
        if (this.isEditing) {
            this.kundeForm.enable();
            this.kundeForm.controls.firma.disable();
        } else {
            this.cancelEdit(); // Revert changes when exiting edit mode
        }
    }

    cancelEdit() {
        if (this.originalKundeVersion) {
            this.populateForm(this.originalKundeVersion);
        }
        this.isEditing = false;
        this.ensureFormIsNotEditable();
    }

    saveChanges() {
        if (this.kundeForm.valid) {
            const updatedKunde = this.kundeFormService.mapKundeFormToKunde(this.kundeForm);
            this.kontakteDataService.updateKundeVersion(updatedKunde).subscribe({
                next: (updatedKundeVersion: KundeVersion) => {
                    this.isEditing = false;
                    this.originalKundeVersion = JSON.parse(JSON.stringify(updatedKundeVersion)); // Save the new state

                    // 🔹 Reset the form with the new values and mark it as pristine
                    this.kundeForm.reset();
                    this.kundeFormService.patchForm(this.kundeForm, updatedKundeVersion, true);
                    this.kundeForm.markAsPristine(); // Ensure pristine state after save
                    
                    this.ensureFormIsNotEditable();
                    this.navigateToKontakt(updatedKundeVersion);
                },
                error: (err) => {
                    console.error('Update failed', err);
                }
            });
        } else {
            console.error('kundeForm is invalid or undefined:', this.kundeForm);
        }
    }

    navigateToInsightlyLink() {
        const externalInsightlyLink: string = 'https://crm.na1.insightly.com/details/';
        const externalCrmKundeId = this.kundeForm.get('externalCrmKundeId').value;
        const kundenType = this.getInsightlyKundeType();
        const externalLink = `${externalInsightlyLink}${kundenType}/${externalCrmKundeId}`;
        window.open(externalLink, '_blank');
    }

    getInsightlyKundeType() {
        const isFirma = this.kundeForm.get('isFirma') ? this.kundeForm.get('isFirma').value : false;
        return isFirma ? 'Organisation' : 'Contact';
    }

    onAssociatedOrganisationSelected(organisation: KundeVersion) {
        this.firmaEmpty = false;
        this.kundeForm = this.kundeFormService.updateCreateAnsprechpersonFormWithFirma(this.kundeForm, organisation);
    }

    fieldCleared() {
        this.firmaEmpty = true;
    }

    private navigateToKontakt(updatedKundeVersion: KundeVersion) {
        // Navigate to the updated kunde
        console.log('Navigating to updated kunde:', updatedKundeVersion);
        this.router.navigate(['/kunden', updatedKundeVersion.id]);
    }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, filter, tap, takeUntil, catchError } from 'rxjs/operators';
import { InsightlyDataService } from '../../../services/insightly-data.service';
import { InsightlyDataMapper } from '../../../services/insightly-data.mapper';
import { InsightlyContact, InsightlyOrganisation } from '../../../services/insightly-data.types';

@Component({
    selector: 'effimod-insightly-kunden-typeahead-search',
    templateUrl: './insightly-kunden-typeahead-search.component.html',
    styleUrls: ['./insightly-kunden-typeahead-search.component.scss']
})
export class InsightlyKundenTypeaheadSearchComponent implements OnInit, OnDestroy {
    @Input() isFirma?: boolean;
    @Input() title: string = 'Name eingeben';
    @Input() delayInMilliseconds: number = 300;
    @Input() placeholder: string;
    @Input() autofocus: boolean = false;
    @Input() minLength: number = 3;

    @Output() kundeSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() fieldCleared: EventEmitter<any> = new EventEmitter<any>();

    private searchTerms = new Subject<string>();
    private destroy$ = new Subject<void>();
    suggestedKunden$: Observable<InsightlyOrganisation[] | InsightlyContact[]>;

    constructor(private insightlyDataService: InsightlyDataService, private insightlyDataMapper: InsightlyDataMapper) {}

    ngOnInit() {
        this.suggestedKunden$ = this.searchTerms.pipe(
            debounceTime(this.delayInMilliseconds),
            distinctUntilChanged(),
            filter(term => term.length >= this.minLength),
            switchMap(term => this.getSuggestions(term)),
            tap(() => this.fieldCleared.emit()),  // Clear field after each search
            catchError(error => {
                console.error('Error occurred while fetching suggestions:', error);
                return [];
            }),
            takeUntil(this.destroy$)
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSelected(selectionEvent: any) {
        if (!selectionEvent) {
            return;
        }

        if (this.isFirma === undefined) {
            throw new Error('isFirma is not set');
            return;
        }

        let kundeVersion;
        if (this.isFirma === true) {
            kundeVersion = this.insightlyDataMapper.mapOrganisationToKundeVersion(selectionEvent);
        } else if (this.isFirma === false) {
            kundeVersion = this.insightlyDataMapper.mapContactToKundeVersion(selectionEvent);
        }

        this.kundeSelected.emit(kundeVersion);
    }

    onCleared() {
        this.fieldCleared.emit();
        this.searchTerms.next('');  // Clear suggestions
    }

    onKeyUp(event: any) {
        this.searchTerms.next(event.target.value);
    }

    onComplete(event: any) {
        if (event && event.query && event.query.trim()) {
            this.searchTerms.next(event.query.trim());
        }
    }

    private getSuggestions(keywords: string): Observable<InsightlyOrganisation[] | InsightlyContact[]> {
        return this.isFirma
            ? this.insightlyDataService.searchOrganisations(keywords)
            : this.insightlyDataService.searchContacts(keywords);
    }
}

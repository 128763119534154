import * as fromRouter from '@ngrx/router-store';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export interface RouterState {
    url: string;
    breadcrumbs: { route: string, name: string }[];
    identifier: string;
    queryParams: {};
}

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterState> {
    serialize(routerState: RouterStateSnapshot): RouterState {
        const { url } = routerState;
        const breadcrumbs = [{ route: '/', name: 'Home' }];
        let identifier = null;
        let fullPath = '';

        let state: ActivatedRouteSnapshot = routerState.root;
        while (state.firstChild) {
            state = state.firstChild;
            if (state.url.length > 0) {
                const segmentPaths = state.url.map(segment => segment.path);
                for (const segment of segmentPaths) {
                    fullPath += `/${segment}`;

                    const name = segment.replace(/-/g, ' ').charAt(0).toUpperCase() + segment.slice(1);
                    breadcrumbs.push({ route: fullPath, name });
                }

                if (!identifier && state.params.id) {
                    identifier = state.params.id;
                }
            }
        }

        return { url, breadcrumbs, identifier, queryParams: state.queryParams };
    }
}

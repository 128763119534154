import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KundeVersion } from '../../../data/interfaces/kunde-version.interface';
import { Adresse } from '../../../data/interfaces/adresse';
import { KundeFormFieldNames } from './constants/kunde-form-field-names';


@Injectable()
export class KundeFormService {

    constructor(private fb: FormBuilder) {
    }

    createVeranstalterVersionForm(isReadonly: boolean) {
        const form = this.fb.group({
            [KundeFormFieldNames.IS_FIRMA]: new FormControl({ value: true, disabled: isReadonly }),
            [KundeFormFieldNames.FIRMA]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.VORNAME]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.NACHNAME]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.NAME]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.STRASSE]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.POSTLEITZAHL]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.ORT]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.EXTERNAL_CRM_KUNDE_ID]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.ORGANISATION_ID]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.DEBITOREN_NUMMER]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.ID]: new FormControl({ value: '', disabled: isReadonly })
        });

        isReadonly ? form.disable() : form.enable();
        return form;
    }

    createAnsprechpersonVersionForm(isReadonly: boolean, isRequired: boolean = false) {
        const requiredValidator = isRequired ? Validators.required : null;

        const form = this.fb.group({
            [KundeFormFieldNames.ID]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.ANREDE]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.VORNAME]: new FormControl({ value: '', disabled: isReadonly }, requiredValidator),
            [KundeFormFieldNames.NACHNAME]: new FormControl({ value: '', disabled: isReadonly }, requiredValidator),
            [KundeFormFieldNames.NAME]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.TELEFON]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.MOBILE]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.EMAIL]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.EXTERNAL_CRM_KUNDE_ID]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.ORGANISATION_ID]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.IS_FIRMA]: new FormControl({ value: false, disabled: isReadonly })
        });

        isReadonly ? form.disable() : form.enable();
        return form;
    }

    createRechnungsadresseVersionForm(isReadonly: boolean) {
        const form = this.fb.group({
            [KundeFormFieldNames.IS_FIRMA]: new FormControl({ value: true, disabled: isReadonly }),
            [KundeFormFieldNames.FIRMA]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.ANREDE]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.VORNAME]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.NACHNAME]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.NAME]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.STRASSE]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.POSTLEITZAHL]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.ORT]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.LAND]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.EXTERNAL_CRM_KUNDE_ID]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.ORGANISATION_ID]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.DEBITOREN_NUMMER]: new FormControl({ value: '', disabled: isReadonly }),
            [KundeFormFieldNames.ID]: new FormControl({ value: '', disabled: isReadonly })
        });

        isReadonly ? form.disable() : form.enable();

        return form;
    }

    toggleDetailViewFormReadOnlyStatusForKontakt(formGroup: FormGroup, isFormReadOnly: boolean) {
        if (isFormReadOnly) {
            formGroup.enable();
        } else {
            formGroup.disable();
        }
    }

    createCreateKundeForm(isReadOnly: boolean = false) {
        const form = this.fb.group({
            [KundeFormFieldNames.IS_FIRMA]: new FormControl({ value: '', disabled: false }),
            [KundeFormFieldNames.FIRMA]: new FormControl({ value: '', disabled: false }, Validators.required),
            [KundeFormFieldNames.ANREDE]: new FormControl({ value: '', disabled: false }, Validators.required),
            [KundeFormFieldNames.VORNAME]: new FormControl({ value: '', disabled: false }, Validators.required),
            [KundeFormFieldNames.NACHNAME]: new FormControl({ value: '', disabled: false }, Validators.required),
            [KundeFormFieldNames.NAME]: new FormControl({ value: '', disabled: false }),
            [KundeFormFieldNames.STRASSE]: new FormControl({ value: '', disabled: false }),
            [KundeFormFieldNames.POSTLEITZAHL]: new FormControl({ value: '', disabled: false }),
            [KundeFormFieldNames.ORT]: new FormControl({ value: '', disabled: false }),
            [KundeFormFieldNames.LAND]: new FormControl({ value: '', disabled: false }),
            [KundeFormFieldNames.TELEFON]: new FormControl({ value: '', disabled: false }),
            [KundeFormFieldNames.MOBILE]: new FormControl({ value: '', disabled: false }),
            [KundeFormFieldNames.EMAIL]: new FormControl({ value: '', disabled: false }, [Validators.email, Validators.required]),
            [KundeFormFieldNames.EXTERNAL_CRM_KUNDE_ID]: new FormControl({ value: '', disabled: true }),
            [KundeFormFieldNames.ORGANISATION_ID]: new FormControl('', Validators.required),
            [KundeFormFieldNames.ID]: new FormControl({ value: '', disabled: true })
        });

        if (isReadOnly) {
            form.disable();
        }

        return form;
    }

    updateCreateAnsprechpersonFormWithFirma(form: FormGroup, firma: KundeVersion) {
        form.patchValue({
            [KundeFormFieldNames.IS_FIRMA]: false,
            [KundeFormFieldNames.FIRMA]: firma.firma,
            [KundeFormFieldNames.STRASSE]: firma.adresse.strasse,
            [KundeFormFieldNames.POSTLEITZAHL]: firma.adresse.plz,
            [KundeFormFieldNames.ORT]: firma.adresse.ort,
            [KundeFormFieldNames.LAND]: firma.adresse.land,
            [KundeFormFieldNames.ORGANISATION_ID]: firma.externalCrmKundeId
        });

        // Mark all controls as dirty after update
        Object.keys(form.controls).forEach(key => {
            form.get(key).markAsDirty();
        });

        return form;
    }

    mapKundeFormToKunde(formGroup: FormGroup) {
        if (!(formGroup instanceof FormGroup)) {
            console.error('Error: Expected formGroup to be an instance of FormGroup, but received:', formGroup);
            throw new Error('Invalid formGroup provided to mapKundeFormToKunde.');
        }

        const rawValues = formGroup.getRawValue();

        const kundeVersion: KundeVersion = {
            kunde: {
                isFirma: false,
                organisationId: rawValues.organisationId ? rawValues.organisationId : null
            },
            anrede: rawValues.anrede,
            vorname: rawValues.vorname,
            nachname: rawValues.nachname,
            adresse: {
                strasse: rawValues.strasse,
                plz: rawValues.postleitzahl,
                ort: rawValues.ort,
                land: rawValues.land
            } as Adresse,
            telefon: rawValues.telefon,
            mobile: rawValues.mobile,
            email: rawValues.email,
            externalCrmKundeId: rawValues.externalCrmKundeId
        } as KundeVersion;

        if (rawValues.id) {
            kundeVersion.id = rawValues.id;
        }

        return kundeVersion;
    }


    public patchForm(form: FormGroup, kundeVersion: KundeVersion, isReadonly: boolean, isRechnungsAdresse: boolean = false) {
        if (!form) {
            return;
        }

        if (!kundeVersion) {
            form.reset();
            return;
        }

        form.patchValue({
            id: kundeVersion.id,
            isFirma: kundeVersion.kunde.isFirma,
            firma: kundeVersion.firma,
            anrede: kundeVersion.anrede,
            vorname: kundeVersion.vorname,
            nachname: kundeVersion.nachname,
            name: kundeVersion.kunde.isFirma ? null : `${kundeVersion.vorname} ${kundeVersion.nachname}`,
            telefon: kundeVersion.telefon,
            mobile: kundeVersion.mobile,
            email: kundeVersion.email,
            externalCrmKundeId: kundeVersion.externalCrmKundeId,
            debitorenNummer: kundeVersion.debitorenNummer,
            organisationId: kundeVersion.kunde.organisationId
        });

        if (isRechnungsAdresse && kundeVersion.rechnungsAdresse) {
            form.patchValue({
                strasse: kundeVersion.rechnungsAdresse.strasse,
                postleitzahl: kundeVersion.rechnungsAdresse.plz,
                ort: kundeVersion.rechnungsAdresse.ort,
                land: kundeVersion.rechnungsAdresse.land
            });
        } else if (kundeVersion.adresse) {
            form.patchValue({
                strasse: kundeVersion.adresse.strasse,
                postleitzahl: kundeVersion.adresse.plz,
                ort: kundeVersion.adresse.ort,
                land: kundeVersion.adresse.land
            });
        }
    }
}

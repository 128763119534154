import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { EntityService } from '../../../shared/interfaces/entity-service.class';
import { KundeVersion } from '../../interfaces/kunde-version.interface';
import { NotificationService } from '../../../shared/services/notification.service';
import { KundenResponse } from '../../graphql/kunden/kunden-response';
import { getKundeById, getKunden } from '../../graphql/kunden/kunden.gql';
import { NotificationUrgency } from '../../../shared/interfaces/notification.interface';
import { CheckboxFilter, TextFilter } from '../../interfaces/base-entity-filter.interface';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { EffimodTableFilterEvent, EffimodTableFilterKeyValue, EffimodTableFilterType } from '../../../shared/interfaces/effimod-table-definition.interface';
import { CreateEffimodKontakteMutationsGQL, UpdateEffimodKontakteMutationsGQL } from '../../graphql/kunden/kontakte/kontakte.mutations';

@Injectable({
    providedIn: 'root'
})
export class BaseKundenDataService extends EntityService {
    public kunden$: BehaviorSubject<KundeVersion[]> = new BehaviorSubject<KundeVersion[]>([]);
    public kunde$: BehaviorSubject<KundeVersion> = new BehaviorSubject<KundeVersion>(null);
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly apollo: Apollo,
        private readonly createEffimodKontakteMutationsGQL: CreateEffimodKontakteMutationsGQL,
        private readonly updateEffimodKontakteMutationsGQL: UpdateEffimodKontakteMutationsGQL,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    public loadEntities(): void {
        if (this.currentFilter.filterBy.length <= 1) { // 1 because of the default filter for isFirma
            return;
        }

        this.setLoadingState(true);
        this.apollo.query<KundenResponse>({
            query: getKunden(this.currentFilter, false),
            variables: {
                pageSize: this.currentFilter.take,
                pageNumber: Math.floor(this.currentFilter.skip / this.currentFilter.take) + 1
            }
        }).subscribe(
            (res) => this.handleSuccessResponse(res.data),
            (error) => this.handleError(error, 'Error loading Kunden')
        );
    }

    protected setLoadingState(isLoading: boolean): void {
        this.loading$.next(isLoading);
    }

    protected handleSuccessResponse(res: KundenResponse): void {
        this.setLoadingState(false);
        this.kunden$.next(res.privateApi.kunden.alle);
    }

    protected handleError(error: any, errorMessage: string): void {
        this.setLoadingState(false);
        this.notificationService.notifiy({text: errorMessage, urgency: NotificationUrgency.error});
        console.error(error);
    }

    public loadEntity(kundeId: number, displayDetails?: boolean): void {
        this.setLoadingState(true);
        this.apollo.query<KundenResponse>({
            query: getKundeById(kundeId, displayDetails === undefined ? true : displayDetails)
        }).subscribe(
            (res) => {
                this.setLoadingState(false);
                this.kunde$.next(res.data.privateApi.kunden.alle[0]);
            },
            (error) => this.handleError(error, 'Error loading customer')
        );
    }

    public saveFilters(filterEvents: EffimodTableFilterKeyValue): void {
        this.currentFilter.filterBy = Object.values(filterEvents).map((event: EffimodTableFilterEvent) => {
            const field = event.filterName;
            const value = event.value;
            const operator = event.operator;
            if (event.filterType === EffimodTableFilterType.checkbox) {
                return new CheckboxFilter({field, value: value === 'true', operator});
            } else {
                return new TextFilter({field, value, operator});
            }
        });
    }

    public loadEntitiesByName(keywords: string, isFirma?: boolean): Observable<KundeVersion[]> {
        this.replaceFilterByName(new TextFilter({field: 'name', operator: 'contains', value: keywords}));
        this.replaceFilterByName(new CheckboxFilter({field: 'isFirma', operator: 'eq', value: isFirma}));
        return this.apollo
            .query<KundenResponse>({query: getKunden(this.currentFilter, false)})
            .pipe(
                map(res => res.data.privateApi.kunden.alle),
                catchError(error => {
                    this.notificationService.notifiy({
                        text: `Fehler beim Laden der Kunden: ${error.message}`,
                        urgency: NotificationUrgency.error
                    });
                    return throwError(error);
                })
            );
    }

    public updateKundeVersion(kundeVersion: KundeVersion): Observable<KundeVersion> {
        this.loading$.next(true);
        return this.updateEffimodKontakteMutationsGQL.mutate({kunde: kundeVersion})
            .pipe(
                map(res => {
                    return res.data.kontakte.updateEffimodKontakt;
                }),
                tap(updatedKunde => {
                    this.kunde$.next(updatedKunde);
                }),
                catchError(err => {
                    this.notificationService.notifiy({
                        text: `Fehler bei der Bearbeitung des Kunden mit dem Namen ${kundeVersion.vorname} ${kundeVersion.nachname}`,
                        urgency: NotificationUrgency.error
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    this.loading$.next(false);
                })
            );
    }

    clearCurrentKunde(): void {
        this.kunde$.next(null);
    }
}

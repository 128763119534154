import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Reservation } from '../interfaces/reservation.interface';
import { BaseEntityFilter, DateTimeFilter, NumberFilter, TextFilter } from '../interfaces/base-entity-filter.interface';
import { Apollo } from 'apollo-angular';
import { getRaumbelegungen, getReservationen, ReservationenApi, ReservationenResponse } from '../graphql/reservationen/reservationen.gql';
import { EntityService } from '../../shared/interfaces/entity-service.class';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GraphQLReservationInput, GraphQLReservationUpdateInput } from '../graphql/reservationen/input/reservation-input';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationUrgency } from 'src/app/shared/interfaces/notification.interface';
import * as moment from 'moment';
import { BestellpositionenDataService } from './bestellpositionen-data.service';
import { EffimodTableFilterOperators } from '../../shared/components/reservationen-list/constants/effimod-table-filter-operators';
import { ReservationenTableFilterNames } from '../../shared/components/reservationen-list/constants/reservationen-table-filter-names';

@Injectable({providedIn: 'root'})
export class ReservationenDataService extends EntityService {

    private veranstaltungReservationenSubject: BehaviorSubject<Reservation[]> = new BehaviorSubject<Reservation[]>([]);
    private reservationenSubject: BehaviorSubject<Reservation[]> = new BehaviorSubject<Reservation[]>([]);
    private reservationSubject: BehaviorSubject<Reservation> = new BehaviorSubject<Reservation>(null);
    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public veranstaltungReservationen$: Observable<Reservation[]> = this.veranstaltungReservationenSubject;
    public reservationen$: Observable<Reservation[]> = this.reservationenSubject;
    public reservation$: Observable<Reservation> = this.reservationSubject;
    public loading$: Observable<boolean> = this.loadingSubject;

    private reservationenLookup$: Subject<void> = new Subject();

    constructor(
        private readonly apollo: Apollo,
        private readonly router: Router,
        private readonly notificationService: NotificationService,
        private readonly bestellpositionenDataService: BestellpositionenDataService) {
        super();
        this.setupReservationLookup();
    }

    public setupReservationLookup() {
        this.reservationenLookup$.pipe(
            switchMap(() => this.apollo.subscribe<ReservationenResponse>({query: getReservationen(this.currentFilter, false)}))
        ).subscribe(
            (res) => {
                this.loadingSubject.next(false);
                this.reservationenSubject.next(res.data.privateApi.reservationen.alleReservationen);
            },
            (error) => {
                console.error(error);
                this.loadingSubject.next(false);
            }
        );
    }

    public loadEntities(): void {
        this.loadingSubject.next(true);
        this.reservationenLookup$.next();
    }

    public loadEntitiesByVeranstaltungId(veranstaltungId: number): Observable<Reservation[]> {
        this.loadingSubject.next(true);
        this.currentFilter.filterBy = [
            new NumberFilter({field: 'veranstaltungId', operator: 'eq', value: veranstaltungId})
        ];
        return this.apollo.subscribe<ReservationenResponse>({
            query: getReservationen(this.currentFilter, false)
        }).pipe(map(
            (res) => {
                this.loadingSubject.next(false);
                const results = res.data.privateApi.reservationen.alleReservationen;
                this.veranstaltungReservationenSubject.next(results);
                return results;
            },
            (error) => {
                this.loadingSubject.next(false);
                console.error(error);
                return [] as Reservation[];
            }
        ));
    }

    public createReservation(reservation: GraphQLReservationInput) {
        this.loadingSubject.next(true);
        this.apollo.mutate(
            {
                mutation: ReservationenApi.mutations.erstellen,
                variables: {reservation}
            })
            .subscribe(
                (response: any) => {
                    this.loadingSubject.next(false);
                    this.veranstaltungReservationenSubject.next([...this.veranstaltungReservationenSubject.value, response.data.reservationen.erstellen]);
                    this.router.navigateByUrl(`/reservationen/${response.data.reservationen.erstellen.reservationsNummer}`);
                    this.notificationService.notifiy({
                        text: `Reservation erstellt`,
                        urgency: NotificationUrgency.success
                    });
                },
                (_) => {
                    this.loadingSubject.next(false);
                    this.notificationService.notifiy({
                        text: `Fehler bei der Erstellung der Reservation mit der Beschriftung ${reservation.beschriftung}`,
                        urgency: NotificationUrgency.error
                    });
                }
            );
    }

    public delete(reservation: Reservation): void {
        const mutationOptions = {
            mutation: ReservationenApi.mutations.delete,
            variables: {
                reservationId: reservation.id
            }
        };

        this.apollo.mutate<any>(mutationOptions)
            .subscribe(res => {
                const deletedReservationId = res.data.reservationen.delete;
                if (reservation.veranstaltung) {
                    this.router.navigateByUrl('veranstaltungen/' + reservation.veranstaltung.id);
                }
                this.notificationService.notifiy({
                    text: `Reservation gelöscht`,
                    urgency: NotificationUrgency.success
                });
            }, error => {
                this.notificationService.notifiy({
                    text: `Fehler beim Löschen der Reservation`,
                    urgency: NotificationUrgency.error
                });
            });
    }

    public update(reservation: GraphQLReservationUpdateInput): void {
        const mutationOptions = {
            mutation: ReservationenApi.mutations.update,
            variables: {reservation}
        };

        this.apollo.mutate<any>(mutationOptions)
            .subscribe(res => {
                // TODO: You don't want trigger two HTTP calls here but for the sake of consistency this is currently used as a workaround (discussed with Rustom 26.11)
                this.loadEntities();
                this.loadEntity(res.data.reservationen.bearbeiten.reservationsNummer);
                this.notificationService.notifiy({
                    text: `Reservation gespeichert`,
                    urgency: NotificationUrgency.success
                });
            }, error => {
                this.notificationService.notifiy({
                    text: `Fehler beim Speichern der Reservation`,
                    urgency: NotificationUrgency.error
                });
            });
    }

    public getReservationenForRaumAndDateRange(raumId: string, beginn: string, ende: string): Observable<Reservation[]> {
        const beginnFilter = new DateTimeFilter({
            field: ReservationenTableFilterNames.BEGINN,
            operator: EffimodTableFilterOperators.GT,
            value: moment(beginn)
        });

        const endeFilter = new DateTimeFilter({
            field: ReservationenTableFilterNames.ENDE,
            operator: EffimodTableFilterOperators.LT,
            value: moment(ende)
        });

        const roomFilter = new NumberFilter({
            field: ReservationenTableFilterNames.RAUM_ID,
            operator: EffimodTableFilterOperators.EQ,
            value: raumId
        });

        const rangeFilter: BaseEntityFilter = {
            take: 3,
            skip: 0,
            filterBy: [beginnFilter, endeFilter, roomFilter]
        };

        return this.apollo.subscribe<ReservationenResponse>({query: getRaumbelegungen(rangeFilter, false)})
            .pipe(
                map(res => {
                    return res.data.privateApi.reservationen.alleRaumbelegungen;
                })
            );
    }

    public loadEntitiesByToday(): Observable<Reservation[]> {
        this.initTodayFilter();
        return this.apollo.subscribe<ReservationenResponse>({query: getReservationen(this.currentFilter, false)})
            .pipe(
                map(res => {
                    return res.data.privateApi.reservationen.alleReservationen;
                }),
                tap(res => {
                    this.reservationenSubject.next(res);
                })
            );
    }

    public loadEntity(reservationsNummer: string): void {
        this.setLoadingState(true);

        this.fetchReservation(reservationsNummer)
            .pipe(
                tap(reservation => {
                    if (!reservation) {
                        this.handleNotFound();
                        return;
                    }
                    this.reservationSubject.next(reservation);
                    this.loadBestellpositionen(reservation.id);
                }),
                finalize(() => this.setLoadingState(false))
            )
            .subscribe({
                error: (error) => this.handleError(error)
            });
    }

    private fetchReservation(reservationsNummer: string): Observable<Reservation | null> {
        return this.apollo.subscribe<ReservationenResponse>({
            query: this.buildReservationQuery(reservationsNummer)
        }).pipe(
            map(response => {
                const result = response.data.privateApi.reservationen.alleReservationen[0];
                return result ? result : null;
            })
        );
    }

    private buildReservationQuery(reservationsNummer: string): any {
        return getReservationen({
            take: 1,
            skip: 0,
            filterBy: [
                new TextFilter({ field: 'reservationsNummer', operator: 'equals', value: reservationsNummer })
            ]
        }, true);
    }

    private setLoadingState(isLoading: boolean): void {
        this.loadingSubject.next(isLoading);
    }

    private handleNotFound(): void {
        this.router.navigate(['/404']); // Navigate to a 404 page if reservation is not found
    }

    private loadBestellpositionen(reservationId: number): void {
        if (reservationId) {
            this.bestellpositionenDataService.loadEntities(reservationId);
        }
    }

    private handleError(error: any): void {
        console.error('Error loading reservation:', error);
        this.setLoadingState(false);
    }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, map, finalize, filter } from 'rxjs/operators';
import { Subscription, Subject, Observable } from 'rxjs';
import { KundenTypOptions } from '../kunden-typ-selector/kunden-typ-options';
import { InsightlyDataService } from '../../services/insightly-data.service';
import { KundeVersion } from '../../../data/interfaces/kunde-version.interface';
import { InsightlyDataMapper } from '../../services/insightly-data.mapper';
import { EffimodKontakteDataService } from '../../../data/services/kunden/effimod-kontakte-data.service';

@Component({
    selector: 'effimod-kunde-selector',
    templateUrl: './kunde-selector.component.html',
    styleUrls: ['./kunde-selector.component.scss']
})
export class KundeSelectorComponent implements OnInit, OnDestroy {
    public isOrganisation: boolean;
    public isLoading: boolean = false;
    public searchSource: 'insightly' | 'effimod' = 'insightly'; // Default source
    public kunden: KundeVersion[] = [];
    public searchKeyword: string = ''; // Keep track of input field value

    private subscriptions: Subscription = new Subscription();
    private searchSubject = new Subject<string>(); // Subject for debounced search

    @Input() showKundenQuelleSelection: boolean = false; // Default hidden
    @Input() readonlyKundenTypSelector: boolean = false;
    @Input() preselectedKundenTypOption: KundenTypOptions = KundenTypOptions.organisation;
    @Output() kundeSelected: EventEmitter<KundeVersion> = new EventEmitter<KundeVersion>();

    constructor(
        private insightlyDataService: InsightlyDataService,
        private insightlyDataMapper: InsightlyDataMapper,
        private effimodKontakteDataService: EffimodKontakteDataService
    ) {}

    ngOnInit() {
        this.isOrganisation = this.preselectedKundenTypOption === KundenTypOptions.organisation;

        // Set up search debounce and request cancellation
        this.subscriptions.add(
            this.searchSubject.pipe(
                debounceTime(300), // Wait 300ms after typing
                distinctUntilChanged(), // Only execute if input has changed
                filter(keyword => {
                    if (keyword.length < 2) {
                        this.isLoading = false;
                        this.kunden = [];
                    }
                    return keyword.length >= 2;
                }), // Only search if at least 2 characters
                switchMap(keyword => this.executeSearch(keyword)) // Cancel previous request if new one comes in
            ).subscribe(results => {
                this.kunden = results;
                this.isLoading = false;
            }, error => {
                console.error('Error loading Kunden:', error);
                this.isLoading = false;
            })
        );
    }

    setSearchSource(source: 'insightly' | 'effimod'): void {
        if (this.searchSource !== source) {
            this.searchSource = source;
            this.kunden = []; // Clear previous results
            this.searchKeyword = ''; // Clear input field
        }
    }

    onKundeSelected(kunde: KundeVersion) {
        this.kundeSelected.emit(kunde);
    }

    onKeywordsChanged(keyword: string) {
        this.searchKeyword = keyword.trim();
        this.isLoading = true;
        this.searchSubject.next(this.searchKeyword); // Send input to the debounced search
    }

    preventEnter(event: KeyboardEvent) {
        event.preventDefault(); // Stop default form submission behavior
    }

    private executeSearch(keyword: string): Observable<KundeVersion[]> {
        let searchObservable: Observable<KundeVersion[]>;

        if (this.searchSource === 'insightly') {
            searchObservable = this.isOrganisation
                ? this.insightlyDataService.searchOrganisations(keyword).pipe(
                    map(orgs => this.insightlyDataMapper.mapOrganisationsToKundeVersion(orgs))
                )
                : this.insightlyDataService.searchContacts(keyword).pipe(
                    map(contacts => this.insightlyDataMapper.mapContactsToKundeVersion(contacts))
                );
        } else {
            searchObservable = this.effimodKontakteDataService.searchEffimodKontakte(keyword);
        }

        return searchObservable.pipe(finalize(() => this.isLoading = false));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

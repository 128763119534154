import { Pipe, PipeTransform } from '@angular/core';
import { InsightlyContact, InsightlyOrganisation } from '../services/insightly-data.types';
import { KundeVersion } from '../../data/interfaces/kunde-version.interface';
import { Adresse } from '../../data/interfaces/adresse';

@Pipe({
    name: 'insightlyToKundeVersion',
    pure: true // Ensures better performance
})
export class InsightlyToKundeVersionPipe implements PipeTransform {

    transform(insightly: InsightlyContact | InsightlyOrganisation): KundeVersion | null {
        if (!insightly) {
            return null;
        }

        if (this.isContact(insightly)) {
            return this.transformContact(insightly);
        } else if (this.isOrganisation(insightly)) {
            return this.transformOrganisation(insightly);
        }

        console.error('Invalid input for InsightlyToKundeVersionPipe', insightly);
        return null;
    }

    /** Check if the input is an InsightlyContact */
    private isContact(input: any): input is InsightlyContact {
        return 'contactId' in input;
    }

    /** Check if the input is an InsightlyOrganisation */
    private isOrganisation(input: any): input is InsightlyOrganisation {
        return 'organisationId' in input;
    }

    /** Transform InsightlyContact to KundeVersion */
    private transformContact(contact: InsightlyContact): KundeVersion {
        return {
            firma: '', // Contacts don't have "firma"
            anrede: contact.salutation || '',
            titel: contact.title || '',
            vorname: contact.firstName || '',
            nachname: contact.lastName || '',
            name: contact.name || '',
            adresseId: undefined,
            adresse: this.mapContactAdresse(contact), // Use contact-specific address mapping
            rechnungsAdresseId: undefined,
            rechnungsAdresse: this.mapContactAdresse(contact), // No separate billing address for contacts
            telefon: contact.phone || '',
            mobile: contact.phoneMobile || '',
            fax: '',
            email: contact.email || '',
            debitorenNummer: contact.debitorenNummer || '',
            externalCrmKundeId: contact.contactId,
            crmId: undefined,
            crm: {} as any, // Placeholder
            veranstaltungen: [],
            kundeId: 0, // Placeholder
            kunde: {} as any, // Placeholder
            createdBy: '', createdOn: undefined, id: 0, lastModifiedBy: '', // placeholders
        };
    }

    /** Transform InsightlyOrganisation to KundeVersion */
    private transformOrganisation(organisation: InsightlyOrganisation): KundeVersion {
        return {
            firma: organisation.organisationName || '',
            anrede: '', // Organisations don't have an "anrede"
            titel: '',
            vorname: '',
            nachname: '',
            name: organisation.name || '',
            adresseId: undefined,
            adresse: this.mapOrganisationAdresse(organisation), // Use organisation-specific address mapping
            rechnungsAdresseId: undefined,
            rechnungsAdresse: this.mapBillingAdresse(organisation), // Use billing address
            telefon: organisation.phone || '',
            mobile: '',
            fax: '',
            email: '', // Organisations don't have direct email fields
            debitorenNummer: organisation.debitorenNummer || '',
            externalCrmKundeId: organisation.organisationId,
            crmId: undefined,
            crm: {} as any, // Placeholder
            veranstaltungen: [],
            kundeId: 0, // Placeholder
            kunde: {} as any, // Placeholder
            createdBy: '', createdOn: undefined, id: 0, lastModifiedBy: '', // placeholders
        };
    }

    /**
     * Maps an address for an InsightlyContact.
     */
    private mapContactAdresse(contact: InsightlyContact): Adresse {
        return {
            createdBy: '', createdOn: undefined, id: 0, lastModifiedBy: '',
            strasse: contact.addressStreet || '',
            plz: contact.addressPostcode || '',
            ort: contact.addressCity || '',
            region: contact.addressState || '',
            land: contact.addressCountry || ''
        };
    }

    /**
     * Maps an address for an InsightlyOrganisation (Shipping Address).
     */
    private mapOrganisationAdresse(organisation: InsightlyOrganisation): Adresse {
        return {
            createdBy: '', createdOn: undefined, id: 0, lastModifiedBy: '',
            strasse: organisation.addressShipStreet || '',
            plz: organisation.addressShipPostcode || '',
            ort: organisation.addressShipCity || '',
            region: organisation.addressShipState || '',
            land: organisation.addressShipCountry || ''
        };
    }

    /**
     * Maps the billing address for an organisation.
     */
    private mapBillingAdresse(organisation: InsightlyOrganisation): Adresse {
        return {
            createdBy: '', createdOn: undefined, id: 0, lastModifiedBy: '',
            strasse: organisation.addressBillingStreet || '',
            plz: organisation.addressBillingPostcode || '',
            ort: organisation.addressBillingCity || '',
            region: organisation.addressBillingState || '',
            land: organisation.addressBillingCountry || ''
        };
    }
}

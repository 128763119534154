import { Pipe, PipeTransform } from '@angular/core';
import { RapportblattBestellposition } from '../../data/interfaces/rapportblatt-bestellposition.interface';

@Pipe({
    name: 'rapportblattBestellpositionenTotalPrice'
})
export class RapportblattBestellpositionenTotalPricePipe implements PipeTransform {

    transform(bestellpositionen: RapportblattBestellposition[] | null | undefined): number {
        if (!bestellpositionen || bestellpositionen.length === 0) {
            return 0;
        }

        let hasMissingArticles = false;
        const total = bestellpositionen.reduce((sum, pos) => {
            if (!pos.artikel || pos.artikel.einheitspreis === undefined) {
                console.warn(`⚠️ Missing or invalid Artikel for Bestellposition ID ${pos.id}`);
                hasMissingArticles = true;
                return sum; // Exclude invalid positions from total
            }
            return sum + (pos.istAnzahl * pos.artikel.einheitspreis);
        }, 0);

        // If any missing articles were found, show a message in the UI
        if (hasMissingArticles) {
            console.warn('⚠️ Some bestellpositionen were skipped due to missing article data.');
        }

        return total;
    }
}

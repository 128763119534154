import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'effimod-step-navigation',
    templateUrl: './step-navigation.component.html',
    styleUrls: ['./step-navigation.component.scss']
})
export class StepNavigationComponent {
    @Input() currentStep!: number;
    @Input() totalSteps!: number;

    @Output() previous = new EventEmitter<void>();
    @Output() next = new EventEmitter<void>();

    goPrevious() {
        if (this.currentStep > 1) {
            this.previous.emit();
        }
    }

    goNext() {
        if (this.currentStep < this.totalSteps) {
            this.next.emit();
        }
    }
}

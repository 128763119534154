import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';


@Component({
    selector: 'effimod-signature-component',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss']
})


export class SignatureComponent implements AfterViewInit {

    @ViewChild('canvas', null) canvas: ElementRef;
    private signaturePad: SignaturePad;
    private finishedSigning: boolean;

    private createSignaturePad(canvas: ElementRef): SignaturePad {
        const ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.nativeElement.width = canvas.nativeElement.offsetWidth * ratio;
        canvas.nativeElement.height = canvas.nativeElement.offsetHeight * ratio;
        canvas.nativeElement.getContext('2d').scale(ratio, ratio);

        const signaturePad = new SignaturePad(this.canvas.nativeElement);

        signaturePad.addEventListener('beginStroke', () => this.drawStart());
        signaturePad.addEventListener('endStroke', () => this.drawComplete());

        return signaturePad;
    }

    drawStart() {
        this.finishedSigning = false;
    }

    drawComplete() {
        this.finishedSigning = true;
    }

    clearSignature() {
        this.signaturePad.clear();
        this.finishedSigning = false;
    }

    ngAfterViewInit(): void {
        this.signaturePad = this.createSignaturePad(this.canvas);
    }

    public isSigned() {
        return this.finishedSigning;
    }

    public asPngBlob() {
        const dataURL = this.signaturePad.toDataURL();
        const [header, base64Data] = dataURL.split(',');
        const mime = header.match(/:(.*?);/)[1]; // Extract the MIME type

        // Decode the base64 string
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Uint8Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Create a Blob from the byte array
        return new Blob([byteNumbers], { type: mime });
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Reservation } from '../../../data/interfaces/reservation.interface';
import { Observable } from 'rxjs';
import { ReservationenDataService } from '../../../data/services/reservationen-data.service';
import * as fromStore from '../../../store';
import { AppState, getVeranstaltungStatusEntities } from '../../../store';
import { select, Store } from '@ngrx/store';
import { first, tap } from 'rxjs/operators';
import { Raum } from '../../../data/interfaces/raum.interface';
import { EffimodTableContent } from '../../interfaces/effimod-table-content.interface';
import { Router } from '@angular/router';
import { VeranstaltungStatus } from 'src/app/data/interfaces/VeranstaltungStatus';
import { BoolToCheckboxHTMLPipe } from '../effimod-table/pipes/bool-to-checkbox-html.pipe';
import { RelevantReservationFlagNameFinderService } from '../../services/relevant-reservation-flag-name-finder.service';
import {
    EffimodTableColumnDefinition, EffimodTableDefinition,
    EffimodTableFilterKeyValue,
    EffimodTableFilterType,
    EffimodTableSelectionEvent,
    EffimodTableSortingEvent
} from '../../interfaces/effimod-table-definition.interface';
import { ReservationenTableDefinitionService } from './services/reservationen-table-definition.service';
import { ReservationenTableService } from './services/reservationen-table.service';

@Component({
    selector: 'effimod-reservationen-list',
    templateUrl: './reservationen-list.component.html',
    styleUrls: ['./reservationen-list.component.scss']
})
export class ReservationenListComponent implements OnInit {

    @Input() isVeranstaltungSpecific: boolean;
    @Input() selectable: boolean;
    @Input() isStatic: boolean;

    @Output() selectionChanged = new EventEmitter<EffimodTableSelectionEvent>();
    @Output() filterChanged = new EventEmitter<EffimodTableFilterKeyValue>();

    public status$: Observable<{ [id: string]: VeranstaltungStatus }>;
    public reservationen$: Observable<Reservation[]>;
    public raeume$: Observable<Raum[]>;

    public content: EffimodTableContent = {
        items: [],
        take: 2,
        skip: 0,
        totalItems: 3
    };

    public tableDefinition: EffimodTableDefinition;

    public previousFilters: EffimodTableFilterKeyValue;

    constructor(
        private router: Router,
        private reservationenDataService: ReservationenDataService,
        private reservationenTableDefinitionService: ReservationenTableDefinitionService,
        private reservationenTableService: ReservationenTableService,
        private store: Store<AppState>,
        private relevantFlagNameFinder: RelevantReservationFlagNameFinderService) {
    }

    ngOnInit() {
        this.initializeReservationen();
        this.initializeRaeume();
        this.initializeStatus();
        this.tableDefinition = this.reservationenTableDefinitionService.getTableDefinition(this.isVeranstaltungSpecific);
        this.addFlagColumnIfRelevant();
    }

    private initializeReservationen() {
        if (!this.isVeranstaltungSpecific) {
            this.previousFilters = this.reservationenDataService.filterEventList;
            this.reservationen$ = this.reservationenDataService.reservationen$.pipe(
                tap(res => {
                    this.content.items = [...res];
                })
            );
        } else {
            this.reservationen$ = this.reservationenDataService.veranstaltungReservationen$.pipe(
                tap(res => {
                    this.content.items = [...res];
                })
            );
        }
    }

    private initializeRaeume() {
        this.raeume$ = this.store.pipe(
            select(fromStore.getRaeume),
            tap(res => {
                const raeume = Object.values(res).map(r => ({ id: r.id, label: r.bezeichnung, value: r.id }));
                const raumColumn = this.tableDefinition.columns.find(c => c.header === 'Raum');
                if (raumColumn) {
                    raumColumn.selectionOptions.push(...raeume);
                }
            })
        );
    }

    private initializeStatus() {
        this.status$ = this.store.pipe(
            select(getVeranstaltungStatusEntities),
            first(),
            tap(res => {
                const status = Object.values(res).map(r => ({ label: r.bezeichnung, value: r.id }));
                const statusColumn = this.tableDefinition.columns.find(c => c.header === 'Status');
                if (statusColumn) {
                    statusColumn.selectionOptions.push(...status);
                }
            })
        );
    }

    private addFlagColumnIfRelevant() {
        this.relevantFlagNameFinder.getFlagSelector().subscribe(x => {
            const fieldName = x.reservationLieferantenFlagName;
            if (fieldName) {
                const lieferantenFlagColumn = {
                    header: x.flagColumnName,
                    filterName: fieldName,
                    filterField: [fieldName],
                    fieldSelector: [fieldName],
                    pipe: new BoolToCheckboxHTMLPipe(),
                    filterType: EffimodTableFilterType.checkbox,
                    sortingDisabled: true,
                    styleClass: 'lieferanten-flag'
                } as EffimodTableColumnDefinition;
                this.tableDefinition.columns.push(lieferantenFlagColumn);
            }
        });
    }

    sortOrderChanged(sortingEvent: EffimodTableSortingEvent) {
        this.reservationenTableService.changeSortOrderWithReload(this.reservationenDataService, sortingEvent);
    }

    onSelectionChanged(event: EffimodTableSelectionEvent) {
        this.selectionChanged.emit(event);
    }

    navigate(data: any) {
        this.router.navigateByUrl('/reservationen/' + data.reservationsNummer);
    }

    filtersChanged(filterEvents: EffimodTableFilterKeyValue) {
        this.reservationenTableService.filtersChanged(this.reservationenDataService, filterEvents);
        this.filterChanged.emit(filterEvents);
    }
}

import gql from 'graphql-tag';
import { BaseEntityFilter } from '../../interfaces/base-entity-filter.interface';
import { DocumentNode } from 'graphql';

export const KundenApi: any = {};

KundenApi.fragments = {
    kundeVersion: gql`
        fragment KundeVersionFragment on KundeVersionType {
            id
            firma
            kunde {
                id
                isFirma
                organisationId
            }
            anrede
            vorname
            nachname
            name
            adresse {
                id
                strasse
                plz
                ort
                region
                land
            }
            rechnungsAdresse {
                id
                strasse
                plz
                ort
                region
                land
            }
            telefon
            mobile
            email
            crm {
                id
                name
            }
            externalCrmKundeId
            debitorenNummer
            validFrom
            validTo
            veranstaltungen {
                id
                titel
                beginn
                ende
                status {
                    id
                    bezeichnung
                }
                veranstalterVersion {
                    id
                    firma
                    vorname
                    nachname
                    name
                    kunde {
                        id
                        isFirma
                        organisationId
                    }
                    crm {
                        id
                        name
                    }
                    externalCrmKundeId
                    adresse {
                        id
                        ort
                    }
                }
                sachbearbeiter {
                    id
                    auth0Id
                    name
                }
            }
        }
    `,
    kunde: gql`
        fragment KundeFragment on KundeType {
            id
            organisationId
            isFirma
        }
    `
};

export function getKunden(filter: BaseEntityFilter, withDetails: boolean, debug = false) {
    const fragment = 'KundeVersionFragment';
    const fragmentImport = KundenApi.fragments.kundeVersion;
    let displayDetails = ', displayDetails: false';
    if (withDetails) {
        displayDetails = ', displayDetails: true';
    }

    let filterByString = '';
    if (filter.filterBy && filter.filterBy.length > 0) {
        const andFilter = filter.filterBy.map(f => f.toQuery()).join(',');
        filterByString += `, filter: {${andFilter}}`;
    }

    let orderByString = '';
    if (filter.orderBy && filter.orderBy.length > 0) {
        orderByString += `, orderBy: {${filter.orderBy.map(f => f.toQuery()).join(',')}}`;
    }

    const query = gql`
        query getKunden($pageSize: Int, $pageNumber: Int) {
            privateApi {
                kunden {
                    alle(pageSize: $pageSize, pageNumber: $pageNumber${filterByString}${orderByString}${displayDetails}) {
                        ... ${fragment}
                    }
                }
            }
        }
        ${fragmentImport}
    `;
    if (debug) {
        console.log(getGqlString(query));
    }
    return query;
}

export function getKundeById(id: number, displayDetails: boolean, debug = false) {
    const fragment = 'KundeVersionFragment';
    const fragmentImport = KundenApi.fragments.kundeVersion;
    const query = gql`
        query getKundeById {
            privateApi {
                kunden {
                    alle(filter: { id: { eq: ${id} } }, displayDetails: ${displayDetails}) {
                        ... ${fragment}
                    }
                }
            }
        }
        ${fragmentImport}
    `;

    if (debug) {
        console.log(getGqlString(query));
    }
    return query;
}

function getGqlString(doc: DocumentNode) {
    return doc.loc && doc.loc.source.body;
}

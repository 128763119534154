import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { PdfFile } from '../../interfaces/pdf-file.interface';

@Component({
    selector: 'effimod-pdf-preview',
    templateUrl: './pdf-preview.component.html',
    styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnChanges {
    @Input() pdfFile: PdfFile | null = null;
    safePdfUrl: SafeResourceUrl | null = null;
    fileName: string = 'rapportblatt.pdf'; // Default filename

    faDownload = faDownload;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pdfFile && this.pdfFile) {
            this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfFile.url);
            this.fileName = this.pdfFile.filename || this.fileName;
        }
    }

    downloadPdf(): void {
        if (!this.pdfFile) { return; }

        const link = document.createElement('a');
        link.href = this.pdfFile.url;
        link.download = this.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

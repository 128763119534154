import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import '@fortawesome/free-solid-svg-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DatepickerComponent } from './components/form/datepicker/datepicker.component';
import { LongTextLabeledInputComponent } from './components/form/long-text-labeled-input/long-text-labeled-input.component';
import { ShortTextLabeledInputComponent } from './components/form/short-text-labeled-input/short-text-labeled-input.component';
import { RelationSelectorComponent } from './components/form/relation-selector/relation-selector.component';
import { DatetimeSelectorComponent } from './components/form/datetime-selector/datetime-selector.component';
import { DropdownComponent } from './components/form/dropdown/dropdown.component';
import { FormInputWrapperComponent } from './components/form/form-input-wrapper/form-input-wrapper.component';
import { TableModule } from 'primeng/table';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EffimodTableComponent } from './components/effimod-table/effimod-table.component';
import { FieldSelectorPipe } from './components/effimod-table/pipes/field-selector.pipe';
import { CheckboxModule, DropdownModule, InputTextareaModule } from 'primeng/primeng';
import { RouterLinkPipe } from './components/effimod-table/pipes/router-link.pipe';
import { EffimodTableFieldComponent } from './components/effimod-table/components/effimod-table-field/effimod-table-field.component';
import { RouterModule } from '@angular/router';
import { KundeDetailsFormComponent } from './components/form/kunde-details-form/kunde-details-form.component';
import { AdresseFormComponent } from './components/form/adresse-form/adresse-form.component';
import { ReservationenListComponent } from './components/reservationen-list/reservationen-list.component';
import { SearchComponent } from './components/search/search.component';
import { ModalCardWrapperComponent } from './components/modal-card-wrapper/modal-card-wrapper.component';
import { KundenTypSelectorComponent } from './components/kunden-typ-selector/kunden-typ-selector.component';
import { KundenTypSelectorFormComponent } from './components/kunden-typ-selector-form/kunden-typ-selector-form.component';
import { KundeErstellenFormComponent } from './components/form/kunde-erstellen-form/kunde-erstellen-form.component';
import { RadioButtonComponent } from './components/form/radio-button/radio-button.component';
import { ActionValidationPipe } from './components/effimod-table/pipes/action-validation.pipe';
import { DatetimeLabeledInputComponent } from './components/form/datetime-labeled-input/datetime-labeled-input.component';
import { VeranstaltungenListComponent } from './components/veranstaltungen-list/veranstaltungen-list.component';
import { EffimodTableFieldEditableComponent } from './components/effimod-table/components/effimod-table-field-editable/effimod-table-field-editable.component';
import { InsightlyKundenTypeaheadSearchComponent } from './components/insightly/insightly-kunden-typeahead-search/insightly-kunden-typeahead-search.component';
import { WeekdayPipe } from './pipes/weekday.pipe';
import { DropdownMultiselectComponent } from './components/form/dropdown-multiselect/dropdown-multiselect.component';
import { PseudoDatetimeSelectorComponent } from './components/pseudo-datetime-selector/pseudo-datetime-selector.component';
import { LabeledLinkComponent } from './components/labeled-link/labeled-link.component';
import { AddNewEffimodTableRowDirective } from './components/effimod-table/directives/add-new-effimod-table-row.directive';
import { EffimodTableRowEditControlsComponent } from './components/effimod-table/components/effimod-table-row-edit-controls/effimod-table-row-edit-controls.component';
import { InsightlyLinkComponent } from './components/insightly/insightly-link/insightly-link.component';
import { InsightlyLabeledLinkComponent } from './components/form/insightly-labeled-link/insightly-labeled-link.component';
import { KundeSelectorComponent } from './components/kunde-selector/kunde-selector.component';
import { KundeErstellenComponent } from './components/kunde-erstellen/kunde-erstellen.component';
import { SimpleKundenListComponent } from './components/kommitee/kunden-simple-list/simple-kunden-list.component';
import { ConfirmBeforeDeleteModalComponent } from './components/deletion-checkback/confirm-before-delete-modal.component';
import { RaumbelegungWarningComponent } from './components/raumbelegung-warning/raumbelegung-warning.component';
import { WeekdayTimePipe } from './pipes/weekdayTime.pipe';
import { EmailLabeledInputComponent } from './components/form/email-labeled-input/email-labeled-input.component';
import { AuthorizationDirective } from './directives/check-permission.directive';
import { BoolToCheckboxHTMLPipe } from './components/effimod-table/pipes/bool-to-checkbox-html.pipe';
import { CurrencyRoundTo5RappenPipe } from './pipes/currencyRoundTo5Rappen.pipe';
import { ArrayToStringPipe } from './components/effimod-table/pipes/array-to-string.pipe';
import { WeekdayOnlyPipe } from './pipes/weekdayOnly.pipe';
import { BenachrichtigungModalComponent } from './components/benachrichtigung-modal/benachrichtigung-modal.component';
import { VoucherDurationSelectorFormComponent } from './components/voucher-duration-selector-form/voucher-duration-selector-form.component';
import { InsightlyKundenTypeaheadSearchResultSelectorComponent } from './components/insightly/insightly-kunden-typeahead-search-result-selector/insightly-kunden-typeahead-search-result-selector.component';
import { VeranstaltungKopierenButtonComponent } from './components/veranstaltung-kopieren-button/veranstaltung-kopieren-button.component';
import { VeranstaltungenKopierenTooltipPipe } from './components/veranstaltung-kopieren-button/veranstaltungen-kopieren-tooltip.pipe';
import { VeranstaltungenBatchBearbeitungButtonComponent } from './components/veranstaltungen-batch-bearbeitung-button/veranstaltungen-batch-bearbeitung-button.component';
import { VeranstaltungenBatchBearbeitungButtonTooltipPipe } from './components/veranstaltungen-batch-bearbeitung-button/veranstaltungen-batch-bearbeitung-button-tooltip.pipe';
import { VeranstaltungenBatchBearbeitungModalComponent } from './components/veranstaltungen-batch-bearbeitung-modal/veranstaltungen-batch-bearbeitung-modal.component';
import { VeranstaltungenBatchAssignAnsprechpersonButtonComponent } from './components/veranstaltungen-batch-assign-ansprechperson-button/veranstaltungen-batch-assign-ansprechperson-button.component';
import { VeranstaltungenBatchAssignAnsprechpersonButtonTooltipPipe } from './components/veranstaltungen-batch-assign-ansprechperson-button/veranstaltungen-batch-assign-ansprechperson-button-tooltip.pipe';
import { VeranstaltungenBatchAssignAnsprechpersonButtonDisabledPipe } from './components/veranstaltungen-batch-assign-ansprechperson-button/veranstaltungen-batch-assign-ansprechperson-button-disabled.pipe';
import { SachbearbeiterOptionsPipe } from './pipes/sachbearbeiter-options.pipe';
import { KundenFormEffimodLinkGeneratorPipe } from './components/form/kunde-details-form/kunden-form-effimod-link-generator.pipe';
import { CrmLinkComponent } from './components/crm-link/crm-link.component';
import { CrmLinkTextPipe } from './components/crm-link/crm-link-text.pipe';
import { VeranstaltungErfassenButtonComponent } from './components/veranstaltung-erfassen-button/veranstaltung-erfassen-button.component';
import { ModalButtonComponent } from './components/modal-button/modal-button.component';
import { VeranstalterSelectionModalButtonComponent } from './kunden/veranstalter-selection-modal-button/veranstalter-selection-modal-button.component';
import { AnsprechpersonSelectionModalButtonComponent } from './kunden/ansprechperson-selection-modal-button/ansprechperson-selection-modal-button.component';
import { RechnungsadresseSelectionModalButtonComponent } from './kunden/rechnungsadresse-selection-modal-button/rechnungsadresse-selection-modal-button.component';
import { ActionsFooterComponent } from './components/actions-footer/actions-footer.component';
import { ActionsFooterRightDirective } from './components/actions-footer/actions-footer-right.directive';
import { ActionsFooterLeftDirective } from './components/actions-footer/actions-footer-left.directive';
import { VeranstaltungenStatusProvisorischZuDefinitivButtonComponent } from './components/veranstaltungen-status-provisorisch-zu-definitiv-button/veranstaltungen-status-provisorisch-zu-definitiv-button.component';
import { VeranstaltungenGenerateAgendaDokumentButtonComponent } from './components/veranstaltungen-generate-agenda-dokument-button/veranstaltungen-generate-agenda-dokument-button.component';
import { VeranstaltungenProvisorischeDeletionButtonComponent } from './components/veranstaltungen-provisorische-deletion-button/veranstaltungen-provisorische-deletion-button.component';
import { InsightlyToKundeVersionPipe } from './pipes/insightly-to-kunde-version.pipe';

@NgModule({
    declarations: [
        PageNotFoundComponent,
        KundeDetailsFormComponent,
        AdresseFormComponent,
        SpinnerComponent,
        DatepickerComponent,
        DatetimeSelectorComponent,
        ShortTextLabeledInputComponent,
        LongTextLabeledInputComponent,
        RelationSelectorComponent,
        DropdownComponent,
        FormInputWrapperComponent,
        EffimodTableComponent,
        FieldSelectorPipe,
        RouterLinkPipe,
        EffimodTableFieldComponent,
        ReservationenListComponent,
        SearchComponent,
        ModalCardWrapperComponent,
        KundenTypSelectorComponent,
        KundenTypSelectorFormComponent,
        KundeErstellenFormComponent,
        RadioButtonComponent,
        ActionValidationPipe,
        DatetimeLabeledInputComponent,
        VeranstaltungenListComponent,
        EffimodTableFieldEditableComponent,
        EffimodTableFieldEditableComponent,
        InsightlyKundenTypeaheadSearchComponent,
        WeekdayPipe,
        WeekdayTimePipe,
        DropdownMultiselectComponent,
        PseudoDatetimeSelectorComponent,
        LabeledLinkComponent,
        AddNewEffimodTableRowDirective,
        EffimodTableRowEditControlsComponent,
        InsightlyLinkComponent,
        InsightlyLabeledLinkComponent,
        KundeSelectorComponent,
        KundeErstellenComponent,
        SimpleKundenListComponent,
        ConfirmBeforeDeleteModalComponent,
        RaumbelegungWarningComponent,
        EmailLabeledInputComponent,
        EmailLabeledInputComponent,
        AuthorizationDirective,
        BoolToCheckboxHTMLPipe,
        CurrencyRoundTo5RappenPipe,
        ArrayToStringPipe,
        WeekdayOnlyPipe,
        WeekdayOnlyPipe,
        VoucherDurationSelectorFormComponent,
        InsightlyKundenTypeaheadSearchResultSelectorComponent,
        BenachrichtigungModalComponent,
        VeranstaltungKopierenButtonComponent,
        VeranstaltungenKopierenTooltipPipe,
        VeranstaltungenBatchBearbeitungButtonComponent,
        VeranstaltungenBatchBearbeitungButtonTooltipPipe,
        VeranstaltungenBatchBearbeitungModalComponent,
        VeranstaltungenBatchAssignAnsprechpersonButtonComponent,
        VeranstaltungenBatchAssignAnsprechpersonButtonTooltipPipe,
        VeranstaltungenBatchAssignAnsprechpersonButtonDisabledPipe,
        KundenFormEffimodLinkGeneratorPipe,
        SachbearbeiterOptionsPipe,
        CrmLinkComponent,
        CrmLinkTextPipe,
        VeranstaltungErfassenButtonComponent,
        ModalButtonComponent,
        VeranstalterSelectionModalButtonComponent,
        AnsprechpersonSelectionModalButtonComponent,
        RechnungsadresseSelectionModalButtonComponent,
        ActionsFooterComponent,
        ActionsFooterLeftDirective,
        ActionsFooterRightDirective,
        VeranstaltungenStatusProvisorischZuDefinitivButtonComponent,
        VeranstaltungenGenerateAgendaDokumentButtonComponent,
        VeranstaltungenProvisorischeDeletionButtonComponent,
        InsightlyToKundeVersionPipe,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        AutoCompleteModule,
        DropdownModule,
        RouterModule,
        CheckboxModule,
        InputTextareaModule
    ],
    exports: [
        CommonModule,
        FontAwesomeModule,
        PageNotFoundComponent,
        KundeDetailsFormComponent,
        AdresseFormComponent,
        DatepickerComponent,
        FormsModule,
        ReactiveFormsModule,
        DatetimeSelectorComponent,
        ShortTextLabeledInputComponent,
        LongTextLabeledInputComponent,
        RelationSelectorComponent,
        DropdownComponent,
        FormInputWrapperComponent,
        EffimodTableComponent,
        RouterModule,
        ReservationenListComponent,
        SearchComponent,
        ModalCardWrapperComponent,
        KundenTypSelectorComponent,
        KundeDetailsFormComponent,
        KundeErstellenFormComponent,
        RadioButtonComponent,
        DatetimeLabeledInputComponent,
        VeranstaltungenListComponent,
        InsightlyKundenTypeaheadSearchComponent,
        WeekdayPipe,
        WeekdayTimePipe,
        PseudoDatetimeSelectorComponent,
        LabeledLinkComponent,
        InsightlyLinkComponent,
        KundeSelectorComponent,
        KundeErstellenComponent,
        SimpleKundenListComponent,
        ConfirmBeforeDeleteModalComponent,
        RaumbelegungWarningComponent,
        AuthorizationDirective,
        CurrencyRoundTo5RappenPipe,
        ArrayToStringPipe,
        VoucherDurationSelectorFormComponent,
        InsightlyKundenTypeaheadSearchResultSelectorComponent,
        BenachrichtigungModalComponent,
        SachbearbeiterOptionsPipe,
        ModalButtonComponent,
        VeranstalterSelectionModalButtonComponent,
        AnsprechpersonSelectionModalButtonComponent,
        RechnungsadresseSelectionModalButtonComponent,
        ActionsFooterComponent,
        VeranstaltungKopierenButtonComponent,
        VeranstaltungenBatchBearbeitungButtonComponent,
        VeranstaltungenBatchAssignAnsprechpersonButtonComponent,
        VeranstaltungErfassenButtonComponent,
        ActionsFooterLeftDirective,
        ActionsFooterRightDirective,
        SpinnerComponent,
        VeranstaltungenStatusProvisorischZuDefinitivButtonComponent,
        VeranstaltungenGenerateAgendaDokumentButtonComponent,
        VeranstaltungenProvisorischeDeletionButtonComponent
    ]
})
export class SharedModule {
}
